import React from 'react'
import { Link } from 'gatsby'
import ReactWOW from 'react-wow'
import Img from 'gatsby-image/withIEPolyfill'
const BannerES = ({bannerImages}) => {
    const one = bannerImages.filter(item => item.node.name === "01")[0];
    const two = bannerImages.filter(item => item.node.name === "02")[0];
    const three = bannerImages.filter(item => item.node.name === "03")[0];
    const four = bannerImages.filter(item => item.node.name === "04")[0];
    const five = bannerImages.filter(item => item.node.name === "05")[0];
    const six = bannerImages.filter(item => item.node.name === "06")[0];
    const mockup = bannerImages.filter(item => item.node.name === "mockup-responsive-no-shadow")[0];

    return (
        <section className="payment_banner_area_two" id="resizeMobile">
            <div className="container">
                <div className="payment_content_two text-center">
                    <h2>Pruebe la versión completa<span>Totalmente Gratis</span></h2>
                    <div className="action_btn d-flex align-items-center justify-content-center">
                        <Link to="/signup/es" className="slider_btn btn_hover">Cree Su Cuenta</Link>
                        <Link to="/signup/es" className="video_btn">Sin Tarjeta de Crédito</Link>
                    </div>
                    <div className="payment_img">
                        <Img style={{width: '100%'}} objectFit='contain' fixed={mockup.node.childImageSharp.fixed}/>
                        <ReactWOW animation="bounceIn" delay="0.1s">
                            <Img className="payment_icon icon_one" fixed={one.node.childImageSharp.fixed}/>
                        </ReactWOW>
                        <ReactWOW animation="bounceIn" delay="0.2s">
                            <Img className="payment_icon icon_two" fixed={two.node.childImageSharp.fixed}/>
                        </ReactWOW>
                        <ReactWOW animation="bounceIn" delay="0.3s">
                            <Img className="payment_icon icon_three" fixed={three.node.childImageSharp.fixed}/>
                        </ReactWOW>
                        <ReactWOW animation="bounceIn" delay="0.4s">
                            <Img className="payment_icon icon_four" fixed={four.node.childImageSharp.fixed}/>
                        </ReactWOW>
                        <ReactWOW animation="bounceIn" delay="0.5s">
                            <Img className="payment_icon icon_five" fixed={five.node.childImageSharp.fixed}/>
                        </ReactWOW>
                        <ReactWOW animation="bounceIn" delay="0.6s">
                            <Img className="payment_icon icon_six" fixed={six.node.childImageSharp.fixed}/>
                        </ReactWOW>
                    </div>
                </div>
                <div className="symbols-pulse active">
                    <div className="pulse-1"></div>
                    <div className="pulse-2"></div>
                    <div className="pulse-3"></div>
                    <div className="pulse-4"></div>
                    <div className="pulse-x"></div>
                </div>
            </div>
        </section>
    )
}

export default BannerES