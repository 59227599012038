import React, { useEffect, useState } from 'react'

// import { graphql } from 'gatsby';
import Layout from "../components/layouts"
import LayoutES from '../components/layouts-es';

import Banner from "../components/pricing/Banner"
import Comparison from "../components/pricing/Comparison"
import Promo from "../components/common/Promo"
import FAQ from "../components/pricing/FAQ"
import SEO from "../components/common/SEO"
import {Link} from 'gatsby'
import BannerES from "../components/pricing-es/Banner";
import ComparisonES from "../components/pricing-es/Comparison";
import store from '../store'
import FAQES from '../components/pricing-es/FAQ';


export const query = graphql`
  query PricingQuery {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
    bannerImages: allFile(
      filter: { relativeDirectory: { eq: "pricing/banner" } }
    ) {
      edges {
        node {
          childImageSharp {
            fixed(width: 690) {
              ...GatsbyImageSharpFixed
            }
          }
          name
        }
      }
    }
  }
`

export default ({ data }) => {
  const seo = {
    title: "Workhub | Pricing | Free Safety and Compliance Management Software",
    description:
      "Managing workplace compliance (EHS, HSE, OHS, OHSMS) has never been easier or more affordable.",
  }

  const PromoText = {
    language: "eng",
    title: "What is Workhub?",
    text:
      "We provide complete health and safety compliance software that allows easy management of training, procedures, policies, inspections, and more, centralized in one easy-to-use hub.",
  }

  const PromoTextES = {
    language: "esp",
    title: "¿Qué es Workhub?",
    text:
      "Proveemos una solución completa al programa de seguridad y salud en el lugar de trabajo que le permitirá de manera sencilla administrar el entrenamiento, políticas, procedimientos, inspecciones y mas, centralizando todo en un solo lugar.",
  }

  const [preferredLanguage, setPreferredLanguage] = useState(
    store.getState().preferredLanguage ?? "eng"
  )
  /**
   * Whenever there is a change in the language selected from the Dropdown component, there is a dispatch of the current selected language
   * when that changes this callback function runs
   * and to stop listening, invoke the function returned by the subscribe
   */

  const unsubscribe = store.subscribe(() => {
    setPreferredLanguage(store.getState().preferredLanguage);
    if(preferredLanguage !== store.getState().preferredLanguage){
      unsubscribe();
    }
  })


  /**
   * whenever the component is dismounted then stop listening to the changes in the preferred language states
   */
  useEffect(() => {
    return () => { 
      console.log(unsubscribe())  
      unsubscribe() 
    }
  }, [])

  if(preferredLanguage === 'esp'){
    return (
      <LayoutES>
        <SEO {...data.site} {...seo} />
        <BannerES bannerImages={data.bannerImages.edges} />
  
        <ComparisonES />
  
        <section className="pricing_testimonial">
          <blockquote>
            <div className="stars">
                <img src="/app-icons/partner-logos/star.png"/>
                <img src="/app-icons/partner-logos/star.png"/>
                <img src="/app-icons/partner-logos/star.png"/>
                <img src="/app-icons/partner-logos/star.png"/>
                <img src="/app-icons/partner-logos/star.png"/>
              </div>
            <p>
            "El <span className="span_gradient" style={{padding: '0px'}}>impresionante </span> bajo costo por mes hace que Workhub sea el programa más asequible en el mercado."
            </p>
         
            <p className="partner_name" id="partner_name" style={{fontSize: '16px!important'}}><Link to="/resources/partners/infinity">Infinity Dynamics</Link></p>
  
            {/* <img className="testimonial_img" src="/app-icons/partner-logos/infinity.jpg"/> */}
          </blockquote>
        </section>
  
        <Promo promo={PromoTextES}/>
        <FAQES />
      </LayoutES>
    )
  }else{
    return (
      <Layout>
        <SEO {...data.site} {...seo} />
        <Banner bannerImages={data.bannerImages.edges} />
  
        <Comparison />
  
        <section className="pricing_testimonial">
          <blockquote>
            <div className="stars">
                <img src="/app-icons/partner-logos/star.png"/>
                <img src="/app-icons/partner-logos/star.png"/>
                <img src="/app-icons/partner-logos/star.png"/>
                <img src="/app-icons/partner-logos/star.png"/>
                <img src="/app-icons/partner-logos/star.png"/>
              </div>
            <p>
              "The <span className="span_gradient" style={{padding: '0px'}}>extremely</span> low cost per month makes Workhub the most affordable
              system available."
            </p>
         
            <p className="partner_name" id="partner_name" style={{fontSize: '16px!important'}}><Link to="/resources/partners/infinity">Infinity Dynamics</Link></p>
  
            {/* <img className="testimonial_img" src="/app-icons/partner-logos/infinity.jpg"/> */}
          </blockquote>
        </section>
  
        <Promo promo={PromoText}/>
        <FAQ />
      </Layout>
    )
  }
}
