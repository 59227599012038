import React, { useState, useEffect, useContext } from "react"
import { UserContext } from '../layouts/UserContext';
import { Link } from "gatsby"
import ReactTooltip from "react-tooltip"
import ReactWOW from "react-wow"
import Collapsible from "react-collapsible"
import styled from "styled-components"

const PriceItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #f8f8fa;
  padding: 15px;
  font-size: 13px;

  position: relative;

  i {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 1rem;
  }

  i:hover {
    cursor: pointer;
  }

  h5 {
    font-size: 16px;
    color: #222d39;
  }

  h5:hover {
    color: #f05123;
  }

  .price-active {
    color: #f05123;
  }
`

const Comparison = () => {

  const location = useContext(UserContext)

  const currency = location 

  return (
    <section className="pricing_area_four pricing_main_padding">
      <ReactTooltip />
      <div className="container pricing_container">
        <div className="hosting_title security_title text-center .intro">
          <ReactWOW animation="fadeInUp" delay="0.2s">
            <h2>
              Feature Comparison
              <span>
                The most robust suite of safety and compliance management tools
                on the market.
              </span>
            </h2>
          </ReactWOW>
          <p className="f_400 f_size_16 l_height30 mb_20">
            And it literally costs less than a Big Mac®! Get the full featured{" "}
            <i>free version</i> and put the tools to the test or jump right in
            and take advantage of our unbelievably low priced standard version.
          </p>
        </div>
        <div className="price_info_two ">
          <div className="price_head">
            <div className="p_head time">
              <h4>Features</h4>
            </div>
            <div className="p_head">
              <p>Free</p>
              <h5>$0 </h5>
              <p>/ month per user</p>
            </div>
            <div className="p_head">
              
              {currency === "US" || currency === undefined ? (
                <>
                  {" "}
                  <p>Standard</p>
                  <h5>$3</h5>
                  <p>
                    (USD) / month per user
                    <br />
                    (minimum 3 users)
                  </p>
                </>
              ) : null}

{currency !== 'US' && currency !== 'CA' && currency !== undefined ? (
                <>
                  {" "}
                  <p>Standard</p>
                  <h5>$3</h5>
                  <p>
                    (USD) / month per user
                    <br />
                    (minimum 3 users)
                  </p>
                </>
              ) : null}

              {currency === "CA" ? (
                <>
                  {" "}
                  <p>Standard</p>
                  <h5>$4</h5>
                  <p>
                    (CAD) / month per user
                    <br />
                    (minimum 3 users)
                  </p>
                </>
              ) : null}
            </div>
          </div>

          {/* Training */}

          <div className="price_body">
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Training Courses</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Training Courses</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Over 60 safety courses included for free or add your own
                    custom courses with quiz.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>

              {/* Procedures */}
            </div>
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Procedures</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Procedures</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Administer your safety procedures and track which workers
                    have reviewed them.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Policies */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Policies</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Policies</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Host your policies with version control and have your
                    workers digitally sign off upon review.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Bulletins */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Bulletins</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Bulletins</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Keep workers up to date on issues, alerts, updates with
                    automatic read receipts to assess readership.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Certificates */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Certificates</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Certificates</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Track any third party certifications for a worker from
                    professional designations and track expirations.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Competencies */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Competencies</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Competencies</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Assure your workers are competent by completing
                    pre-requisite tasks and assessing performance.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Worksite Screening */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Worksite Screening</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Worksite Screening</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Screen workers and visitors as they access your worksites
                    with custom questionaires and access logs.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>

              {/* WorkerID */}
            </div>
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>WorkerID</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">WorkerID</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Get your compliance data on the go with the WorkerID mobile
                    app.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Forms */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Forms</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Forms</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Track vehicle inspections, safety meetings, incident form
                    and more with numerous submission types.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Assets */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Assets</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Assets</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Track and manage important assets and track / schedule
                    maintenance and inspections
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Orientations */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Orientations</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Orientations</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Host your orientations with convenient prime contractor
                    access and track completion.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Surveys */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Surveys</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Surveys</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Deploy anonymous surveys to your workers to find out how
                    they feel about your company's safety program.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Inspections */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Inspections</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Inspections</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Schedule required inspections on assets, document findings
                    and assign action items on issues.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>

              {/* Incident Tracking */}
            </div>
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Incident Tracking</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Incident Tracking</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Track incidents and gather all your incident documents in
                    one place.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Safety Data Sheets */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Safety Data Sheets</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Safety Data Sheets</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Add your SDS manual in Workhub and ensure workers have
                    access to it on the go right from their phone or tablet.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Audit Standards */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Audit Standards</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Audit Standards</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Run a self audit using popular audit protocols and see where
                    you may have any deficiencies to work on before your audit
                    happens.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Safety Recognition */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Safety Recognition</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Safety Recognition</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Integrate recognition system to reward workers for
                    compliance
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* No Ads */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>No Ads</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">No Ads</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>No Ads</div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* SMS Notifications */}
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>SMS Notifications</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">SMS Notifications</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Bulletins and Alerts can be delivered via SMS for immediate
                    response (US and Canada only)
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close Icon" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* White-Labelling */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Whitelabeling</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Whitelabeling</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Customize the look to match your brand with custom logo and
                    color styling
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close Icon" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>

              {/* Branded Reports */}
            </div>
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Branded Reports</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Branded Reports</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Reports are generated with your brand for a professional
                    look
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close Icon" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Large Uploads */}
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Large Uploads (10mb)</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Large Uploads (10mb)</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Upload your documents with less limitation on file size
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close Line" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Phone Support */}
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Phone Support</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Phone Support</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Dedicated support across via phone, email, and IM for all
                    your questions
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close line" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Custom Permissions */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Custom Permissions</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Custom Permissions</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                    Custom permissions on user groups to segregate your system
                    based on roles and users
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close Line" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Location Assignments */}
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Location Assignments</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Location Assignments</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>Specificy assignments across various locations</div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close Line" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            <div className="pr_list">
              <div className="price_item"></div>
              <div className="price_item">
                <Link to="/signup/" className="price_btn btn_hover">
                  Choose Free
                </Link>
              </div>
              <div className="price_item">
                <Link to="/signup/" className="price_btn btn_hover">
                  Choose Standard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Comparison