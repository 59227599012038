import React from 'react'
import { Link } from 'gatsby'
const Promo = (props) => {
    


    return (
        <section className="h_action_promo_area">
            <div className="overlay_bg" style={{background: `url('/workhub-tiles-bg.jpg')`}}></div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-8">
                        <div className="h_promo_content">
                            <h2>{props.promo.title}</h2>
                            <p>{props.promo.text}</p>
                        </div>
                    </div>
                    <div className="col-md-4 text-right">
                        <Link to="/about/what-is-workhub/" className="slider_btn btn_hover">{props.promo.language === 'esp' ? 'Nosotros': 'Overview'}</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Promo;