import React from 'react'
import Collapsible from "react-collapsible"

function handleAccordion(newPosition) {
    console.log(newPosition)
  }


const FAQES = () => {

    return (
        <div className="accordion-container">
              <div
                className="tab-content faq_content"
                id="myTabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="purchas"
                  role="tabpanel"
                  aria-labelledby="purchas-tab"
                >
                  <h3 className="f_p f_size_24 f_500 mb_20 pricing_h3">
                  Preguntas frecuentes

                  </h3>
                  <div id="accordion">
                    <div className="card">
                      <Collapsible
                        trigger={
                          <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                              <span className="btn btn-link collapsed">
                              ¿Que se considera un usuario?
                                <i className="ti-plus">+</i>
                              </span>
                            </h5>
                          </div>
                        }
                        triggerWhenOpen={
                          <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                              <span className="btn btn-link">
                              ¿Que se considera un usuario?
                                <i style={{ display: "block" }}>-</i>
                              </span>
                            </h5>
                          </div>
                        }
                        transitionTime={200}
                      >
                        <div className="card-body">
                        Diferenciamos nuestros usuarios de la siguiente manera, activos o inactivos, los usuarios activos se facturan normalmente. Los usuarios inactivos no se facturan, ya que esta cuentas estarán invernando, y dichos usuarios no podrán acceder. El precio para pagar será prorrateado dependiendo el tiempo que duren activos en el mes.                        </div>
                      </Collapsible>
                    </div>
                    <div className="card">
                      <Collapsible
                        trigger={
                          <div className="card-header" id="headingTwo">
                            <h5 className="mb-0">
                              <span className="btn btn-link collapsed">
                              ¿Hay algún costo de introducción?
                                <i className="ti-plus">+</i>
                              </span>
                            </h5>
                          </div>
                        }
                        triggerWhenOpen={
                          <div className="card-header" id="headingTwo">
                            <h5 className="mb-0">
                              <span className="btn btn-link">
                              ¿Hay algún costo de introducción?
                                <i style={{ display: "block" }}>-</i>
                              </span>
                            </h5>
                          </div>
                        }
                        transitionTime={200}
                      >
                        <div className="card-body">
                        ¡No! Nos gusta mantener todo simple. El pago de su suscripción es todo lo que usted pagara. Lo invitamos a que consulte con nuestros competidores por estos costos de introducción.                        </div>
                      </Collapsible>
                    </div>
                    <div className="card">
                      <Collapsible
                        trigger={
                          <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                              <span className="btn btn-link collapsed">
                              ¿Los administradores se facturan diferente a los trabajadores?
                                <i className="ti-plus">+</i>
                              </span>
                            </h5>
                          </div>
                        }
                        triggerWhenOpen={
                          <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                              <span className="btn btn-link">
                              ¿Los administradores se facturan diferente a los trabajadores?
                                <i style={{ display: "block" }}>-</i>
                              </span>
                            </h5>
                          </div>
                        }
                        transitionTime={200}
                      >
                        <div className="card-body">
                        Todos los usuarios de facturan al mismo precio, no se paga adicional por administradores o supervisores.
                        </div>
                      </Collapsible>
                    </div>
                    <div className="card">
                      <Collapsible
                        trigger={
                          <div className="card-header" id="headingfour">
                            <h5 className="mb-0">
                              <span className="btn btn-link collapsed">
                              ¿Hay contratos?
                                <i className="ti-plus">+</i>
                              </span>
                            </h5>
                          </div>
                        }
                        triggerWhenOpen={
                          <div className="card-header" id="headingfour">
                            <h5 className="mb-0">
                              <span className="btn btn-link">
                              ¿Hay contratos?
                                <i style={{ display: "block" }}>-</i>
                              </span>
                            </h5>
                          </div>
                        }
                        transitionTime={200}
                      >
                        <div className="card-body">
                        No, Facturamos mes a mes y usted esta libre de cancelar en cualquier momento.
                        </div>
                      </Collapsible>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    )
}

export default FAQES;
