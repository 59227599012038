import React, { useContext } from "react"
import { UserContext } from '../layouts/UserContext';
import { Link } from "gatsby"
import ReactTooltip from "react-tooltip"
import ReactWOW from "react-wow"
import Collapsible from "react-collapsible"
import styled from "styled-components"

const PriceItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: #f8f8fa;
  padding: 15px;
  font-size: 13px;

  position: relative;

  i {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 1rem;
  }

  i:hover {
    cursor: pointer;
  }

  h5 {
    font-size: 16px;
    color: #222d39;
  }

  h5:hover {
    color: #f05123;
  }

  .price-active {
    color: #f05123;
  }
`

const ComparisonES = () => {

  const location = useContext(UserContext)

  const currency = location 

  return (
    <section className="pricing_area_four pricing_main_padding">
      <ReactTooltip />
      <div className="container pricing_container">
        <div className="hosting_title security_title text-center .intro">
          <ReactWOW animation="fadeInUp" delay="0.2s">
            <h2>
            Comparación de las Características
              <span>
              La solución más robusta en el mercado para la administración de seguridad y salud en el trabajo.
              </span>
            </h2>
          </ReactWOW>
          <p className="f_400 f_size_16 l_height30 mb_20">
          ¡Y literalmente vale menos que una Big Mac®! 
          Obtenga la versión completa gratuita y ponga las herramientas a prueba o tome 
          ventaja del precio increíblemente bajo de la versión standard.
          </p>
        </div>
        <div className="price_info_two ">
          <div className="price_head">
            <div className="p_head time">
              <h4>Características</h4>
            </div>
            <div className="p_head">
              <p>Gratis</p>
              <h5>$0 </h5>
              <p>/ mes por usuario</p>
            </div>
            <div className="p_head">
              
              {currency === "US" || currency === undefined ? (
                <>
                  {" "}
                  <p>Standard</p>
                  <h5>$3</h5>
                  <p>
                    (USD) / mes por usuario
                    <br />
                    (Mínimo 3 Usuarios)
                  </p>
                </>
              ) : null}

{currency !== 'US' && currency !== 'CA' && currency !== undefined ? (
                <>
                  {" "}
                  <p>Standard</p>
                  <h5>$3</h5>
                  <p>
                    (USD) / month per user
                    <br />
                    (minimum 3 users)
                  </p>
                </>
              ) : null}

              {currency === "CA" ? (
                <>
                  {" "}
                  <p>Standard</p>
                  <h5>$4</h5>
                  <p>
                    (CAD) / month per user
                    <br />
                    (minimum 3 users)
                  </p>
                </>
              ) : null}
            </div>
          </div>

          {/* Training */}

          <div className="price_body">
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Cursos en Línea</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Cursos en Línea</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Mas de 70 cursos de seguridad gratuitos o agregue sus propios cursos con exámenes.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>

              {/* Procedures */}
            </div>
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Procedimientos</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Procedimientos</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Administre sus procedimientos y rastree el cumplimiento de sus trabajadores.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Policies */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Políticas</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Políticas</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Almacene sus políticas con control de versión y permítales a sus trabajadores aceptar de manera digital sus políticas.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Bulletins */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Boletines</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Boletines</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Mantenga a sus trabajadores al día con alertas y actualizaciones sobre inconvenientes, con reporte de lectura cuando los boletines son leídos.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Certificates */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Certificados</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Certificados</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Rastree el cumplimiento de certificado externos de designaciones profesionales y rastree expiraciones.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Competencies */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Competencias</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Competencias</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Asegúrese de que sus trabajadores son competentes completando prerrequisitos y evaluando sus habilidades.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Worksite Screening */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Control de acceso</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Control de acceso</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Controle el acceso de sus trabajadores y visitantes cuando accedan a los lugares de trabajo con cuestionarios personalizados y mantenga un registro del acceso.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>

              {/* WorkerID */}
            </div>
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>ID de Trabajador</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">ID de Trabajador</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Visualice la información de su cumplimiento en cualquier lugar desde la aplicación móvil de ID del trabajador
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Forms */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Formas</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Formas</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Rastree inspecciones de vehículos, reuniones, o cualquier otro documento con múltiples envíos.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Assets */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Activos</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Activos</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Mantenga registro de los activos importantes de su empresa, monitoree y programe mantenimiento e inspecciones.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Orientations */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Orientaciones</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Orientaciones</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Almacene sus orientaciones permita el acceso a sus contratistas para completarlas.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Surveys */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Encuestas</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Encuestas</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Envié encuestas anónimas a sus trabajadores y averigüe como ellos se sienten con el programa de seguridad y salud.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Inspections */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Inspecciones</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Inspecciones</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Programe inspecciones requeridas en sus activos, documente hallazgos y asigne objetos de acción por algún inconveniente.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>

              {/* Incident Tracking */}
            </div>
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Seguimiento de Incidentes</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Seguimiento de Incidentes</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Rastree incidentes y mantenga todos los documentos relacionados en un solo lugar.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Safety Data Sheets */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Hojas de Datos de Seguridad</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Hojas de Datos de Seguridad</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Agregue sus HDS en Workhub y asegúrese que sus trabajadores tienen acceso a estas directamente desde sus dispositivos móviles.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Audit Standards */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Preparación de Auditorias</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Preparación de Auditorias</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Haga una auto auditoría utilizando los protocolos populares de seguridad y analice en que áreas tiene deficiencias y haga mejoras antes de su auditoria.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Safety Recognition */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Reconocimientos de Seguridad</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Reconocimientos de Seguridad</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Integre un sistema de reconocimiento para premiar los trabajadores por su cumplimiento.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* No Ads */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Sin Anuncios</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Sin Anuncios</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>Sin Anuncios</div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* SMS Notifications */}
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Mensajes de Texto</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Mensajes de Texto</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Envíe alertas por mensajes de texto para obtener una respuesta inmediata (Solo para Canadá y USA)
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close Icon" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* White-Labelling */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Personalización</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Personalización</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Personalice el look de su cuenta, colocando su logo y los colores de su empresa.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close Icon" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>

              {/* Branded Reports */}
            </div>
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Reportes con su Logo</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Reportes con su Logo</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Los reportes que se generan en la aplicación incluirán su marca.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close Icon" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Large Uploads */}
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Subida de Archivos Grandes (10mb)</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Subida de Archivos Grandes (10mb)</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Suba sus documentos en diversos componentes con menos limitaciones.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close Line" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Phone Support */}
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Soporte Telefónico</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Soporte Telefónico</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Soporte dedicado a través de teléfono, correo electrónico y chat para responder todas sus dudas
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close line" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Custom Permissions */}

            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Permisos Personalizados</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Permisos Personalizados</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>
                  Personalice los permisos de grupos de usuarios dependiendo el tipo de acceso y sus roles.
                  </div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close Line" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            {/* Location Assignments */}
            <div className="pr_list">
              <PriceItem>
                <Collapsible
                  trigger={
                    <div>
                      <h5>Asignaciones por locación</h5>
                      <i className="ti-plus">+</i>
                    </div>
                  }
                  triggerWhenOpen={
                    <div>
                      <h5 className="price-active">Asignaciones por locación</h5>
                      <i className="price-active">-</i>
                    </div>
                  }
                  transitionTime={200}
                >
                  <div>Especifique asignaciones a través de múltiples locaciones</div>
                </Collapsible>
              </PriceItem>
              <div className="price_item" data-title="Free">
                <h5 className="check">
                  <img src="/app-icons/layout-icons/close-line.svg" alt="Close Line" />
                </h5>
              </div>
              <div className="price_item" data-title="Standard">
                <h5 className="check">
                  <img
                    src="/app-icons/layout-icons/green-checkmark-line.svg"
                    alt="Checkmark"
                  />
                </h5>
              </div>
            </div>

            <div className="pr_list">
              <div className="price_item"></div>
              <div className="price_item">
                <Link to="/signup/es" className="price_btn btn_hover">
                Ir Gratis
                </Link>
              </div>
              <div className="price_item">
                <Link to="/signup/es" className="price_btn btn_hover">
                Ir Standard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ComparisonES