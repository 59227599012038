import React from 'react'
import Collapsible from "react-collapsible"

function handleAccordion(newPosition) {
    console.log(newPosition)
  }


const FAQ = () => {

    return (
        <div className="accordion-container">
              <div
                className="tab-content faq_content"
                id="myTabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="purchas"
                  role="tabpanel"
                  aria-labelledby="purchas-tab"
                >
                  <h3 className="f_p f_size_24 f_500 mb_20 pricing_h3">
                    Frequently Asked Questions
                  </h3>
                  <div id="accordion">
                    <div className="card">
                      <Collapsible
                        trigger={
                          <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                              <span className="btn btn-link collapsed">
                              What's considered a user?
                                <i className="ti-plus">+</i>
                              </span>
                            </h5>
                          </div>
                        }
                        triggerWhenOpen={
                          <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                              <span className="btn btn-link">
                              What's considered a user?
                                <i style={{ display: "block" }}>-</i>
                              </span>
                            </h5>
                          </div>
                        }
                        transitionTime={200}
                      >
                        <div className="card-body">
                        We distinguish between two types of users, active and inactive. Any user marked active is invoiced. Inactive users are hibernated and don't have access to login or have their compliance tracked, we prorate the cost for these workers based on the time in the current month they were active. You choose when to set a user active or inactive.
                        </div>
                      </Collapsible>
                    </div>
                    <div className="card">
                      <Collapsible
                        trigger={
                          <div className="card-header" id="headingTwo">
                            <h5 className="mb-0">
                              <span className="btn btn-link collapsed">
                              Are there onboarding costs?
                                <i className="ti-plus">+</i>
                              </span>
                            </h5>
                          </div>
                        }
                        triggerWhenOpen={
                          <div className="card-header" id="headingTwo">
                            <h5 className="mb-0">
                              <span className="btn btn-link">
                              Are there onboarding costs?
                                <i style={{ display: "block" }}>-</i>
                              </span>
                            </h5>
                          </div>
                        }
                        transitionTime={200}
                      >
                        <div className="card-body">
                        No! We like to keep it simple. Your subscription fee is all you pay. Go ahead, ask our competitors about their upfront and onboarding costs.
                        </div>
                      </Collapsible>
                    </div>
                    <div className="card">
                      <Collapsible
                        trigger={
                          <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                              <span className="btn btn-link collapsed">
                              Are admins or supervisors invoiced at a different rate than workers?
                                <i className="ti-plus">+</i>
                              </span>
                            </h5>
                          </div>
                        }
                        triggerWhenOpen={
                          <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                              <span className="btn btn-link">
                              Are admins or supervisors invoiced at a different rate than workers?
                                <i style={{ display: "block" }}>-</i>
                              </span>
                            </h5>
                          </div>
                        }
                        transitionTime={200}
                      >
                        <div className="card-body">
                        All users are invoiced at the same rate and you don't pay extra to make someone an administrator or supervisor.
                        </div>
                      </Collapsible>
                    </div>
                    <div className="card">
                      <Collapsible
                        trigger={
                          <div className="card-header" id="headingfour">
                            <h5 className="mb-0">
                              <span className="btn btn-link collapsed">
                              Is there a contract?
                                <i className="ti-plus">+</i>
                              </span>
                            </h5>
                          </div>
                        }
                        triggerWhenOpen={
                          <div className="card-header" id="headingfour">
                            <h5 className="mb-0">
                              <span className="btn btn-link">
                              Is there a contract?
                                <i style={{ display: "block" }}>-</i>
                              </span>
                            </h5>
                          </div>
                        }
                        transitionTime={200}
                      >
                        <div className="card-body">
                        Nope. We invoice month to month and you are free to cancel at any time.
                        </div>
                      </Collapsible>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    )
}

export default FAQ;
